<template>
    <div class="height100">
        <header-title :title="title">
            <rs-button size="small" slot="center" class="button-click" type="primary" @click="handleEdit">编辑</rs-button>
        </header-title>
        <rs-form size="small" class="form-readonly demo-ruleForm form-70" :model="editForm" label-width="156px" style="padding-right: 20px;" label-position="right" status-icon ref="editForm">
            <!-- <rs-row> -->
                <rs-col :span="12">
                    <rs-form-item label="组织名称:">
                        {{editForm.orgName}}
                    </rs-form-item>
                </rs-col>
                <rs-col :span="12">
                    <rs-form-item label="上级组织:">
                        {{editForm.parentOrgName}}
                    </rs-form-item>
                </rs-col>
                <rs-col :span="12">
                    <rs-form-item label="组织描述:">
                        {{editForm.remark}}
                    </rs-form-item>
                </rs-col>
                <rs-col :span="12">
                    <rs-form-item label="组织状态:">
                        <span>{{editForm.status | status}}</span>
                    </rs-form-item>
                </rs-col>
            <!-- </rs-row> -->
            <rs-col :span="12">
                <rs-form-item label="创建人:">
                    {{editForm.createName}}
                </rs-form-item>
            </rs-col>
            <rs-col :span="12">
                <rs-form-item label="创建时间:">
                    {{editForm.createTime}}
                </rs-form-item>
            </rs-col>
            <rs-col :span="12">
                <rs-form-item label="更新人:">
                    {{editForm.modifyName}}
                </rs-form-item>
            </rs-col>
            <rs-col :span="12">
                <rs-form-item label="更新时间:">
                    {{editForm.modifyTime}}
                </rs-form-item>
            </rs-col>
        </rs-form>
    </div>
</template>

<script>
    import Http from '@/api/organizationApi'
    import utils from '@/utils/utils'
    import headerTitle from '@/components/header'
    export default {
        components: {
            headerTitle
        },
        data(){
            return{
                rules: {
                    orgName: [
                        {required: true, message: '请输入组织名称', trigger: 'change'},
                        { max: 20, message: '最多20个字符', trigger: 'change' }
                    ],
                    status: [{required: true, message: '请选择状态', trigger: 'change'}]
                },
                editForm: {},
                form: {},
                id: '',
                title: '组织详情'
            }
        },
        
        methods:{
            // 获取详情
            handleDetail() {
                Http.queryOrganizationDetail({id: this.id}).then(res=>{
                    console.log(res, 'ress')
                    this.editForm = res.data.data
                    this.form = {
                        parentOrgSysCode: res.data.data.parentOrgSysCode,
                        parentOrgName: res.data.data.parentOrgName,
                        orgName: res.data.data.orgName,
                        remark: res.data.data.remark?res.data.data.remark:'',
                        status: res.data.data.status,
                        sort: res.data.data.sort,
                        id: res.data.data.id
                    }
                })
            },
            handleEdit() {
                this.$router.push(`/organizationEdit?id=${this.id}`)
            },
        },
        created(){
            this.id = this.$route.query.id
            this.handleDetail()
        },
        filters:{
            status(val) {
                return val == 1?'已启用':'已停用'
            }
        },
    }
</script>

<style scoped>
.height100 {
    height: 100%;
}
.box {
    /* padding: 10px 20px; */
    background: #ffffff;
    height: calc(100% - 19px);
    margin: 4px 0 15px 0;
    overflow: auto;
}
.box-l {
    border: 1px solid rgba(0,0,0,0.1);
    margin-left: 1.2%;
    margin-right: 1.2%;
}
.box-r {
    border: 1px solid rgba(0,0,0,0.1);
    margin-left: 1.2%;
}
.add-role-button {
    border-bottom: 1px solid #EDEDED;
    padding: 10px 13px 9px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.edit-button {
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #EDEDED;
    padding: 3px 9px;
}
.add_auth_button {
    float: right;
    margin-right: 9px;
}
.table-box {
    padding: 0 9px 15px 13px;
}
.rs-form-item{
    margin-bottom:0;
}
/deep/.rs-form-item__label {
    color: #666666;
}
</style>
    